import React, { useEffect, useRef, useState } from 'react'
import BlogAgileSection from './agiel-section'
import BackgroundImage from 'gatsby-background-image'
import {Link,navigate} from 'gatsby'
import { PageNotFoundSvg } from '../assets/images/404_page'
const BlogList =({ searchvalue, listview, data})=>{
    return(
            <div className="white-bg blog-main-sections">
            <div className="pth-blog-body blog-section-padding">
                        <div className="blog-main-section">
                        <div className="theiaStickySidebar">         
                        <div className="blog-section">
                        <div className="blog-listing-wrapper cell-wrapper">
                        <div className="post-listing grid">
                        {data 
                        ? 
                        data.allMarkdownRemark.edges.length !== 0 
                        ? 
                        data.allMarkdownRemark.edges.map((dt,i)=>{
                            return <PostItem listview={listview} data={dt} idd={i}/>
                        }) 
                        :
                        <div className="no-results-found">
                        <PageNotFoundSvg>
                        <g id="Group_213" data-name="Group 213" transform="translate(3180.716 6833)">
                        <g id="Group_208" data-name="Group 208" transform="translate(155 44)">
                            <foreignObject id="Stop_wasting_a_9" data-name="Stop wasting a " className="cls-n14" transform="translate(824 344)" width="20cm" height="10cm">
                            {searchvalue !== `` ? <p>You search - {searchvalue} - did not match any article</p>
                            : <p>No data found</p>}
                            </foreignObject>
                            
                            
                        </g>
                        
                        </g>
                        </PageNotFoundSvg>
                        </div>
                        : null}
                
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                   
                        <BlogSideSection/>

                    </div>
                </div>

  

     
    )
}


const PostItem =({listview,data, idd})=>{
    return(
        <div 
        key={data.node.id} 
        className={`post-item item tab-1 Company Owner Project Manager Developer ${listview ==='1' ? `full-width-list` : ``}`}
        onClick={()=>navigate(`${data.node.fields.slug}`)}>
            <div className="post-image-container">
                <div className="hs-featured-image-wrapper">
                    <Link className="hs-featured-image-link" to={data.node.fields.slug}>
                    {/* <span style={{backgroundImage:`url('${data.node.frontmatter.featuredimage.publicURL}')`}} className="hs-featured-image"/> */}
                    <BackgroundImage
                    className="hs-featured-image"
                    style={{backgroundSize:`100%`}}
                    Tag="span"
                    fadeIn={false}
                    critical={true}
                    preserveStackingContext={true}
                    fluid={data.node.frontmatter.featuredimage.childImageSharp.fluid}
                    />
                    </Link>
                </div>
            </div>
            <div className="post-body-container">
            <div className="post-header">
                <h3 style={{textAlign:"center"}}>
                 <Link to={data.node.fields.slug} style={{textDecoration:`none`}}>{data.node.frontmatter.title}</Link></h3>
                <div className="description-data">
                  <p>{data.node.excerpt}</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default BlogList



const BlogSideSection =()=>{
    const [listining, setListining] = useState(false)
    const [top, setTop] = useState(0)
    const wr = useRef(null)
    const wr1 = useRef(null)
    useEffect(()=>{
        if(window !== undefined && window.screen.width >= 768 && listining !== true){
        window.addEventListener('scroll',watchScroll)
      return () =>{
        window.removeEventListener('scroll',watchScroll)
      }}
    },[top])


    let olds = []
    const watchScroll=(e)=>{   
        let d = wr.current
        let d1 = wr1.current
        olds.push(window.scrollY);

        if(olds.length > 0){
        if(olds[0] < window.scrollY){
        if(d.offsetTop - 95 < window.scrollY){
            if(top === 0){
                setTop(95)
            }
        }
        }else{
            if(d1.offsetTop - 95 > window.scrollY){
                setTop(0)
               
            }
        }
        }
       


    }
    return(
        <div className="blog-box-3 blog-sidebar-section">
            <div className="theiaStickySidebar">
                <div className="row-fluid-wrapper row-depth-2 row-number-7 ">
                    <div className="row-fluid "  ref={wr1} >
                        
                    <div 
                    ref={wr}
                    className="span12 widget-span widget-type-cell sidebar-wrapper"                    
                    // style={{position:`${top !== 0 ? `fixed`:`relative`}`, top:top, left:left}}
                    >
                    <div className="agile-box" style={{position:`${top !== 0 ? `fixed`:`relative`}`, transform:top !== 0 ? `translateY(150px)` : null,top:`0px`,maxWidth:`280px`,width:`100%`, transition:`all 0.5s`}}>
                      <BlogAgileSection/>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
