import React,{useState,useEffect,useRef} from 'react'
import Layout from '../layouts'
import BlogHeadSection from '../components/blog/section1'
import {ElementWrap} from '../components/blog/section2'
import PostFilter from '../components/blog/post-filter'
import BlogList from '../components/blog/blog-list'
import {roleDatafunction} from '../components/data_functions'
import Helmet from 'react-helmet'

export const BlogPostTemplate = ({data, roleData, setRoleData, setLifecyclevalue}) => {
const [searchvalue,setSearchvalue] = useState(``)
const windowGlobal = typeof window !== 'undefined' && window
const [blogListSearchedData,setBlogListSearchedData] = useState(null)  
const [pageview,setPageview] = useState('')
const [blogsection1, setBlogsection1] = useState(false)
const [listview,setListview] = useState(windowGlobal 
                                    ?
                                    localStorage.getItem('blogview') ? localStorage.getItem('blogview') :'0'
                                    :`0`) 

const scrollLisFunction = () =>{
  if(window){
    if(window.scrollY !==0){
      sessionStorage.setItem(`blog_list_y`,window.scrollY)
    }    
  }

}   

useEffect(()=>{

  if(pageview===''){
  setPageview('fp-viewing-introduction fp-viewing-introductionn')
  }
if(window !== undefined){
  window.addEventListener('scroll',scrollLisFunction)
  
}
return ()=>{
  window.removeEventListener('scroll',scrollLisFunction)
}

},[listview,blogListSearchedData])




const handlelistView=(e)=>{
  if(window !== undefined){
  localStorage.setItem('blogview',e)
  setListview(e)
}
}
const clearSearchfield =()=>{
  setSearchvalue(``)
  setBlogListSearchedData(null)
}
const handleBlogSearch=(e)=>{
  setSearchvalue(e.target.value.toLowerCase())
  let olddata = data
  let newdata = {
    allMarkdownRemark:{edges:[]}
  }
  olddata.allMarkdownRemark.edges.forEach(el=>{if(el.node.frontmatter.title.toLowerCase().includes(e.target.value.toLowerCase()) === true){
    newdata.allMarkdownRemark.edges.push(el)
  }})
  setBlogListSearchedData(newdata)
}

  return (
    <div className={`body-container container-fluid ${pageview}`}>
       <Helmet defer={false}>
        <title>Blog | JDSolutions</title>
        <meta property="og:title" content="Blog | JDSolutions" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
      
        <div className={`${listview} section ${!blogsection1 ? 'blog-top-padding' : ''}`}>
          <PostFilter 
          listview={listview}
           handlelistView={handlelistView}
            roleData={roleData} 
            setRoleData={setRoleData} 
            handleBlogSearch={handleBlogSearch} 
            searchvalue={searchvalue} 
            clearSearchfield={clearSearchfield}
            setLifecyclevalue={setLifecyclevalue}
            />
          <BlogList 
          searchvalue={searchvalue} 
          listview={listview} 
          data={blogListSearchedData !== null ? 
          blogListSearchedData 
          : 
          data
          }/>
        </div>
  
        </div>
   </div>
  )
}


const BlogPost = ({data}) => {
const [page, setPage] = useState('blog hs-blog-listing formal')
const [lifecyclevalue, setLifecyclevalue] = useState(null)
const [plifecyclevalue, setPlifecyclevalue] = useState(null)
const [fldata, setFldata] = useState(null)
const handleLifeFilterData=(de)=>{
  const ftft = data
  const newdata = {
    allMarkdownRemark:{edges:[]}
  }
  ftft.allMarkdownRemark.edges.forEach(el=>{
    if(el.node.frontmatter.lifecycle >= de+1){
      newdata.allMarkdownRemark.edges.push(el)
    }
  })
  setFldata(newdata)
}
useEffect(()=>{
  if(lifecyclevalue !== null){
    if(plifecyclevalue === null){
      setPlifecyclevalue(lifecyclevalue)
      handleLifeFilterData(lifecyclevalue)
    }else if(lifecyclevalue !== plifecyclevalue){
      handleLifeFilterData(lifecyclevalue)
      setPlifecyclevalue(lifecyclevalue)
    }  
  }
},[lifecyclevalue,fldata])
  return (
      <Layout page={page}>
      <BlogPostBefore data={fldata !== null ? fldata : data} setLifecyclevalue={setLifecyclevalue} fldata={fldata}/>
    </Layout>
  )
}
export default BlogPost


const BlogPostBefore = ({data, setLifecyclevalue, fldata}) => {
  const [tagFilterData, setTagFilterData] = useState(null)
  const [roleData,setRoleData]=useState(roleDatafunction)
const handleTagFilterData=()=>{
  const ftft = data
  const newdata = {
    allMarkdownRemark:{edges:[]}
  }
  const ggs = []
  roleData.forEach(el=>{
    if(el.checked){
      ggs.push(el.tag.toLowerCase())
    }
  });

  let i;
  let dtats = [];

  if(ggs.length >0){
    for(i=0; i < ggs.length; i++){  
      if( i === 0){
        ftft.allMarkdownRemark.edges.forEach(el=>{
          if(el.node.frontmatter.tags.includes(ggs[i]) === true){
            dtats.push(el)
          }
        })  
      }else{
        dtats=dtats.filter(el => el.node.frontmatter.tags.includes(ggs[i]) === true);
      }
       
    }
    newdata.allMarkdownRemark.edges = [...dtats]
    setTagFilterData(newdata)
  }else{
    setTagFilterData(null)
  }

  
 
}
useEffect(()=>{
 
    handleTagFilterData() 
  
},[roleData, fldata])
useEffect(()=>{

},[tagFilterData])
    return <BlogPostTemplate data={tagFilterData !== null ? tagFilterData : data} roleData={roleData} setRoleData={setRoleData} setLifecyclevalue={setLifecyclevalue}/>
  }

  
