import React from 'react'
import BlogPost from '../../templates/blog-post'
import { graphql, StaticQuery } from 'gatsby'
export default () => (
    <StaticQuery
      query={graphql`
      query BlogPostList {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-detail"}}}) {
          edges {
            node {
              excerpt(pruneLength: 180)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                tags
                lifecycle
              }
            }
          }
        }
      }
      
      
      
      `}
      render={(data) => <BlogPost data={data} />}
    />
  )
  