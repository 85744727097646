import React,{useState,useEffect} from 'react'
import GridView from '../assets/images/grid-view'
import ListView from '../assets/images/list-view'
import search from '../assets/images/search.svg'
import CompanyLifeCycle from './company-lifecycle'
import { ElementWrap } from './section2'
const PostFilter=({listview, handlelistView, roleData, setRoleData,handleBlogSearch,searchvalue,clearSearchfield, setLifecyclevalue})=>{
    const [filter,setFilter]=useState(false)
    const handleFilter=()=>{
        if(filter === true){
            setFilter(false)
        }else{
            setFilter(true)
        }
        
    }
    useEffect(()=>{

    },[filter,listview])
    return(

        <div className="blog-filter-container">



        <div className="blog-filter-section">
            <div className="filter-box-click">
                <a className={ `listing-page-back ${filter ? `close-added` : ``}`} onClick={handleFilter}>
                    {filter 
                    ? 
                    <React.Fragment>Hide Filter <i className="fa fa-angle-up"></i></React.Fragment> 
                    : 
                    <React.Fragment>Show Filter <i className="fa fa-angle-down"></i></React.Fragment>}
                    </a>
            </div>

            <div className="filter-box-content">
               <ContentTabs roleData={roleData} setRoleData={setRoleData} setLifecyclevalue={setLifecyclevalue} filter={filter}/>
            </div>
        </div>


        


        <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell ">
            <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell top-filter-section">
            <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
            <div className="row-fluid ">
            <div id="scroller-anchor" className="span12 widget-span widget-type-cell wrapper">
            <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
            <div className="row-fluid ">           
            

            <div className="span4 widget-span widget-type-raw_html blank-grid"><h4>hello</h4></div>


            <div className="span4 widget-span widget-type-raw_html list-filter-type">
            <div className="cell-wrapper layout-widget-wrapper">
            <span id="hs_cos_wrapper_module_150819839125227" 
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
            <ul className={listview}>
            <li className={`grid-list-type ${listview === '0' ? `active-list` : `inactive`}`} onClick={()=>handlelistView('0')}>
  
            <GridView/>
            </li>
            <li className={`case-list-type ${listview === '1' ? `active-list` : `inactive`}`} onClick={()=>handlelistView('1')}>
            <ListView/>
            </li>
            </ul>
            </span>
            </div> 
            </div>
            <div className="span4 widget-span widget-type-raw_html search-icons">
            <div className="cell-wrapper layout-widget-wrapper">
            <span id="hs_cos_wrapper_module_150819839938728" 
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
            <input type="text" className="quicksearch" value={searchvalue} onChange={handleBlogSearch} id="search1"/>
                {/* <img className="ico-se" src={search} alt="search_icon"/> */}
                {searchvalue.toString().length !== 0 ? 
                <i className="fa fa-times ico-close" onClick={clearSearchfield}></i>
                : <i className="fa fa-search ico-se"></i>}
            </span>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
               

            </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        </div>
    )
}
export default PostFilter;


const ContentTabs=({roleData, setRoleData, setLifecyclevalue, filter})=>{
    const [activetab,setActivetab]=useState(0)
    const handleTabclick=(e)=>{
        setActivetab(e)
    }
    return (
        <div className={`category-filter-section filter-visible`} style={{display: filter ? `block` : `none`}}>
            <div className="p-data-show-type">
                <div className="fliter-option cell-wrapper layout-widget-wrapper">
                            
                    <p
                        className={ `show-filter-cycle-lifecycle ${activetab === 0 ? `active-element` : ``}`}
                        onClick={()=>handleTabclick(0)}
                        >Company Lifecyle</p>
                    <p 
                    className={ `show-filter-cycle-lifecycle ${activetab === 1 ? `active-element` : ``}`}
                    onClick={()=>handleTabclick(1)}
                    >Your Role</p>
                    
                </div>
            </div>

            <div className=" cates-fileter-outer">
                <div className="cell-wrapper layout-widget-wrapper">
                    <span className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
                        <span style={{display: activetab === 0  ? `block` : `none`}}><CompanyLifeCycle setLifecyclevalue={setLifecyclevalue}/></span>
                        {activetab === 1 && <ElementWrap roleData={roleData} setRoleData={setRoleData}/>}
                    </span>
                </div>
            </div>
            
            
        </div>
    )
}